<script lang="ts" setup>
import { ref } from "vue"
import { useRouter, useRoute } from "vue-router"

import { getChildById } from "@/services/Child"
import { appendAssessmentNote } from "@/services/Assessments"
import {
  createOrReplaceGrowthAssessment,
  getGrowthAssessmentForChild,
} from "@/services/GrowthAssessment"
import { getAssessmentNoteSchema } from "@/schemas/Common"
import { setCurrentViewContext } from "@/utils/GlobalState"
import ChildHeader from "@/components/children/ChildHeader.vue"
import { gettext } from "@/utils/Translation"

const { $gettext } = gettext
const route = useRoute()
const router = useRouter()
const child = ref(null)
const childId = parseInt(route.params.childId as string)
const assessmentId = parseInt(route.params.assessmentId as string)
const growthAssessment = ref(null)
const schema = getAssessmentNoteSchema({
  label: $gettext("Assessment notes"),
  help: $gettext("Notes are optional. You may note details about the child's health, feeding, or nutrition such as changes in health or appetite."),
})

async function getData() {
  child.value = await getChildById(childId)
  growthAssessment.value = await getGrowthAssessmentForChild(assessmentId)
  setCurrentViewContext(child.value, "child")
}

async function submitForm(data) {
  if (data.note?.trim()) {
    growthAssessment.value.notes = appendAssessmentNote(growthAssessment.value.notes, data.note.trim())
    await createOrReplaceGrowthAssessment(child.value, growthAssessment.value)
  }
  router.push({ name: "MeasurementsRequired", params: { childId, assessmentId } })
}

getData()
</script>

<template>
  <article>
    <ChildHeader
      v-if="child"
      :child="child"
    />

    <h1 class="title">
      {{ $gettext("Growth") }}
    </h1>
    <template v-if="child && child.id">
      <FormKit
        type="form"
        :submit-label="$gettext('Save')"
        :submit-attrs="{ inputClass: 'button is-primary' }"
        @submit="submitForm"
      >
        <FormKitSchema :schema="schema" />
      </FormKit>
    </template>
  </article>
</template>
