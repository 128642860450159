import { max as d3Max, min as d3Min, extent } from "d3-array"
import { axisBottom, axisLeft } from "d3-axis"
import { scaleLinear, scaleTime } from "d3-scale"
import { select as d3Select, selectAll as d3SelectAll } from "d3-selection"
import { curveBasis, area as d3Area, line as d3Line } from "d3-shape"
import dayjs from "dayjs"

import { IGrowthAssessment } from "@/db"
import { Child } from "@/models/Child"
import { GrowthAssessment } from "@/models/GrowthAssessment"
import { getAllSavedGrowthAssessmentsForChild } from "@/services/GrowthAssessment"
import { getD3Locales } from "@/services/Translation"
import { gettext } from "@/utils/Translation"
import { isKnown, range } from "@/utils/Utilities"
import chartMetadata from "./growthcharts/chartMetadata"

const { $gettext } = gettext

// Returns the dynamically imported json-stored growth chart data.
export async function getZScoreLineData(assessment: GrowthAssessment): Promise<object> {
  const sex = assessment.child.sex == "male" ? "boys" : "girls"
  let ageRange: string
  if (assessment.ageInYears < 2) {
    ageRange = "0_2"
  }
  else if (assessment.ageInYears < 5) {
    ageRange = "2_5"
  }
  else if (assessment.ageInYears < 10) {
    ageRange = "5_10"
  }
  else {
    ageRange = "10_19"
  }
  return await import(`./growthcharts/${sex}_${ageRange}.json`)
}

/*
 * Return an object mapping categories to an array of chart abbreviations appropriate to be shown for the given assessment.
 * @args {Object}    assessment – object representing a growth assessment
 * @return {Array}   an object with values that are arrays of strings, e.g.
    {
      weight: ["wfa", "bmi"],
      headSize: ["hcfa"],
      height: ["lfa"]
    }
 */
interface chartTypes {
  weight?: Array<string>
  height?: Array<string>
  headSize?: Array<string>
  muac?: Array<string>
}
function getChartTypesForAssessment(assessment: GrowthAssessment): chartTypes {
  const results = {}
  // We assume that if we don't have a z score for the chart under consideration
  // that that represents an extreme measurement, and we shouldn't include that
  // growth chart. (That is, we wouldn't be able to display the chart at all.)

  // BMI/Weight for length/height
  const weightCharts = []
  if (assessment.weightInKilograms && assessment.lengthInCm) {
    // Weight-for-Height/Length if under 5; otherwise BMI
    if (assessment.ageInYears > 5) {
      if (isKnown(assessment.zScoreBmi)) { weightCharts.push("bmi") }
    }
    else {
      if (assessment.ageInYears >= 2) {
        if (isKnown(assessment.zScoreWfh)) { weightCharts.push("wfh") }
      }
      else {
        if (isKnown(assessment.zScoreWfl)) { weightCharts.push("wfl") }
      }
    }
  }
  if (assessment.weightInKilograms && assessment.ageInYears <= 10) {
    if (isKnown(assessment.zScoreWfa)) { weightCharts.push("wfa") }
  }
  if (weightCharts.length) {
    results["weight"] = weightCharts
  }
  if (assessment.lengthInCm && isKnown(assessment.zScoreLhfa)) {
    results["height"] = [assessment.ageInYears >= 2 ? "hfa" : "lfa"]
  }
  if (assessment.headCircumferenceInCm && assessment.ageInYears < 5 && isKnown(assessment.zScoreHca)) {
    results["headSize"] = ["hcfa"]
  }
  if (assessment.muacInCm) {
    results["muac"] = [assessment.ageInYears < 5 ? "acfa" : "muac_cutoff"]
  }
  return results
}

/*
 * Return an array of the appropriate growth assessments to be considered for display for
 * the recommendations for the supplied assessment. Specifically, return the assessments
 * for this child that took place after the child was a certain age, and up to and including
 * the assessment that was supplied. (That is, don't include 2yo assessments if growth charts
 * for the 5-10y period will be used, and don't include assessments that took place *after* the
 * supplied one.
 *
 * Additionally, mark up each object with some additional computed fields useful for growth charts.
 * @args {Object}    child
 * @args {Object}    assessment
 * @return {Array}  Array of assessments
 */
async function getFilteredAssessmentsForChild(assessment: GrowthAssessment): Promise<Array<GrowthAssessment>> {
  let minAgeInYears: number
  if (assessment.ageInYears < 2) {
    minAgeInYears = 0
  }
  else if (assessment.ageInYears < 5) {
    minAgeInYears = 2
  }
  else if (assessment.ageInYears < 10) {
    minAgeInYears = 5
  }
  else {
    minAgeInYears = 10
  }
  const assessmentData = await getAllSavedGrowthAssessmentsForChild(assessment.child.id)
  const assessments = []
  assessmentData.forEach((a: IGrowthAssessment) => {
    assessments.push(new GrowthAssessment(assessment.child, a))
  })
  return assessments.filter(a => a.ageInYears >= minAgeInYears && a.dateOfAssessment <= assessment.dateOfAssessment)
}

/*
 * Return an object with properties for measurements and tooltips. Given a chart type and a list of assessments,
 * filter the list based on that chart types criteria, distill to measurements we want, and form the list of
 * tooltips appropriate for those points.
 * @args {string}    chartType
 * @args {Object}    currentAssessment
 * @args {Array}    assessments -- all assessments that are candidates for this growth chart (inc currentAssessment)
 * @return {Array}  an array of objects with properties: metadata, measurements, tooltips
 */
function getMetaDataAndMeasurementsAndTooltips(chartType: string, currentAssessment: GrowthAssessment, assessments: Array<GrowthAssessment>) {
  let filter: (a: GrowthAssessment) => boolean
  let measurementsExtractor: (a: GrowthAssessment) => Array<number>
  let tooltipsExtractor: (a: GrowthAssessment) => string
  switch (chartType) {
    case "bmi":
      filter = (a) => {
        return (
          a.weightInKilograms &&
          a.lengthInCm &&
          (a == currentAssessment || (!a.lengthIsDirty && !a.weightIsDirty))
        )
      }
      measurementsExtractor = (a) => [a.ageInMonths, a.bmi]
      tooltipsExtractor = (a) => `BMI: ${a.bmi.toFixed(1)}; age: ${a.niceAge}`
      break
    case "wfh":
      filter = (a) =>
        a.weightInKilograms &&
        a.lengthInCm &&
        (a == currentAssessment || (!a.lengthIsDirty && !a.weightIsDirty))
      tooltipsExtractor = (a) => `Height: ${a.adjustedLengthInCm.toFixed(1)} cm; weight: ${a.weightInKilograms.toFixed(1)} kg`
      measurementsExtractor = (a) => [a.adjustedLengthInCm, a.weightInKilograms]
      break
    case "wfl":
      filter = (a) =>
        a.weightInKilograms &&
        a.lengthInCm &&
        (a == currentAssessment || (!a.lengthIsDirty && !a.weightIsDirty))
      tooltipsExtractor = (a) => `Length: ${a.lengthInCm.toFixed(1)} cm; weight: ${a.weightInKilograms.toFixed(1)} kg`
      measurementsExtractor = (a) => [a.lengthInCm, a.weightInKilograms]
      break
    case "wfa":
      filter = (a) => a.weightInKilograms && (a == currentAssessment || !a.weightIsDirty)
      measurementsExtractor = (a) => [a.ageInMonths, a.weightInKilograms]
      tooltipsExtractor = (a) => `Weight: ${a.weightInKilograms.toFixed(1)} kg; age: ${a.niceAge}`
      break
    case "hfa":
      filter = (a) => a.lengthInCm && (a == currentAssessment || !a.lengthIsDirty)
      measurementsExtractor = (a) => [a.ageInMonths, a.adjustedLengthInCm]
      tooltipsExtractor = (a) => `Height: ${a.adjustedLengthInCm.toFixed(1)} cm; age: ${a.niceAge}`
      break
    case "lfa":
      filter = (a) => a.lengthInCm && (a == currentAssessment || !a.lengthIsDirty)
      measurementsExtractor = (a) => [a.ageInMonths, a.lengthInCm]
      tooltipsExtractor = (a) => `Length: ${a.lengthInCm.toFixed(1)} cm; age: ${a.niceAge}`
      break
    case "hcfa":
      filter = (a) => a.headCircumferenceInCm && (a == currentAssessment || !a.headSizeIsDirty)
      measurementsExtractor = (a) => [a.ageInMonths, a.headCircumferenceInCm]
      tooltipsExtractor = (a) => `Head Size: ${a.headCircumferenceInCm.toFixed(1)} cm; age: ${a.niceAge}`
      break
    case "acfa":
      filter = (a) => Boolean(a.muacInCm)
      measurementsExtractor = (a) => [a.ageInMonths, a.muacInCm]
      tooltipsExtractor = (a) => `Arm Size: ${a.muacInCm.toFixed(1)} cm; age: ${a.niceAge}`
      break
  }
  const filteredAssessments = assessments.filter(filter)
  const measurements = filteredAssessments.map(measurementsExtractor)
  const tooltips = filteredAssessments.map(tooltipsExtractor)
  return { metadata: chartMetadata[chartType], measurements, tooltips, chartType }
}

/*
 * Return an object with properties for each relevant growth metric, whose value
 * is an array of lists of objects with properties: metadata, measurements, tooltips.
 * Also, include a boolean property dirtyDataWereExcluded.
 */
export async function getGrowthChartData(assessment: GrowthAssessment) {
  const assessments = await getFilteredAssessmentsForChild(assessment)
  const dirtyDataWereExcluded = assessments
    .filter((a: GrowthAssessment) => a.hasDirtyData)
    .length
  const categories = getChartTypesForAssessment(assessment)
  const results = { dirtyDataWereExcluded: Boolean(dirtyDataWereExcluded) }
  for (const [category, chartTypes] of Object.entries(categories)) {
    results[category] = []
    chartTypes.forEach((chartType: string) => {
      if (chartType === "muac_cutoff") {
        results[category].push({ chartType })
      }
      else {
        results[category].push(getMetaDataAndMeasurementsAndTooltips(chartType, assessment, assessments))
      }
    })
  }
  return results
}

// Inspired largely by https://github.com/nathanleiby/growthchart
export function getGrowthChartDisplayer(chartData, zScoreLineData) {
  // Create the background lines
  // Get data to build chart's 'background lines' depending on chartType
  const { metadata, tooltips, measurements } = chartData
  const { chartName } = metadata
  const { data } = zScoreLineData[chartName] // z-score data

  // Save the last tuple so that I can label it
  const lastTuples = []

  // Boundaries for graph, based on growth chart bounds
  let xMin = 999999 // (arbitrary large number)
  let yMin = 999999
  let xMax = 0
  let yMax = 0
  let lastTuple = []
  let lineData = []

  // For weight-for-length, fit the chart to the available data for readability
  if (chartName === "wfl" || chartName === "wfh") {
    xMin = Math.min(yMin, d3Min(measurements.map(d => d[0])))
    xMax = Math.max(yMax, d3Max(measurements.map(d => d[0])))
    // Add a small buffer on either side.
    xMin -= 1
    xMax += 3
    // Winnow down the line tuples to just the ones in range
    data.forEach((lineData: Array<Array<number>>, i: number) => {
      const newLineData = []
      lineData.forEach((pair: Array<number>) => {
        if (pair[0] >= xMin && pair[0] <= xMax) {
          newLineData.push(pair)
        }
      })
      lastTuple = newLineData[newLineData.length - 1]
      lastTuples.push(lastTuple)
      yMin = Math.min(newLineData[0][1], yMin)
      yMax = Math.max(lastTuple[1], yMax)
      data[i] = newLineData
    })

    // Make sure the child's Z-scores aren't the real min/max y value
    yMin = Math.min(yMin, d3Min(measurements.map(d => d[1])))
    yMax = Math.max(yMax, d3Max(measurements.map(d => d[1])))
  }
  else {
    // For all the other charts, just get the x/y min/max vals
    for (let i = 0; i < data.length; i += 1) {
      lineData = data[i] // A particular std dev line: a list of 'tuples'
      lastTuple = lineData[lineData.length - 1]
      lastTuples.push(lastTuple)
      xMax = Math.max(lastTuple[0], xMax)
      yMax = Math.max(lastTuple[1], yMax)
      xMin = Math.min(lineData[0][0], xMin)
      yMin = Math.min(lineData[0][1], yMin)
    }

    // Ensure that our smallest child y-value isn't going to fall off the chart
    let smallestChildYVal = 99999
    for (let i = 0; i < measurements.length; i += 1) {
      smallestChildYVal = Math.min(measurements[i][1], smallestChildYVal)
    }
    if (smallestChildYVal < yMin) {
      // We don't want the yMin to just be the lowest child value; we don't want
      // it on the x-axis.
      yMin = smallestChildYVal - (yMin - smallestChildYVal)
    }
  }

  function drawChart() {
    const { numLocale } = getD3Locales()

    // Graph formatting, in pixels
    const margin = {
      top: 40,
      right: 20,
      bottom: 100,
      left: 50,
    }

    const width = Math.max(
      (document.getElementsByClassName("growth-chart-inner-wrapper")[0] as HTMLElement)?.offsetWidth || 0 - (margin.left + margin.right),
      210,
    )
    const height = Math.max(width / 2 - (margin.top + margin.bottom), 150)
    let patientDotSize = 1 // Size of dots for actual child's data points
    let tickCount = 7 // Number of ticks in the axes

    // Take advantage of larger view port sizes by growing dot sizes.
    if (width > 450) {
      patientDotSize = 2
      tickCount = 8
      if (width > 600) {
        patientDotSize = 3
        tickCount = 10
      }
    }

    // Graph scale; domain and range
    const xScale = scaleLinear().domain([xMin, xMax]).range([0, width])
    const yScale = scaleLinear().domain([yMin, yMax]).range([height, 0])

    // Line generating function
    const line = d3Line()
      .x((d) => xScale(d[0]))
      .y((d) => yScale(d[1]))
      .curve(curveBasis)

    // Area under the curve, for highlighting regions
    const area = d3Area()
      .x(line.x())
      .y1(line.y())
      .y0(yScale(0))
      .curve(curveBasis)

    const chart = d3Select(".growth-chart-inner-wrapper")
    chart.select(".chart-placeholder").remove() // remove spinner placeholder
    chart.select(".growth-chart").remove() // clear existing growth chart svg

    const svg = chart
      .append("svg")
      .attr("width", width + margin.left + margin.right)
      .attr("height", height + margin.top + margin.bottom)
      .classed("chart growth-chart", true)
      .append("g")
      .attr("transform", `translate(${margin.left},${margin.top})`)

    // Baseline growth curves
    const lines = svg.selectAll(".lines").data(data).enter()

    lines.append("path").attr("class", "area").attr("d", area)

    lines.append("path").attr("class", "line").attr("d", line)

    // Add unique class names for each line, so we can color them selectively
    svg.selectAll(".line").each(function classifyLines(current, index) {
      const existingClasses = this.classList
      existingClasses.add(`line-${metadata.lines[index]}`)
      this.setAttribute("class", existingClasses)
    })
    // Ditto for the areas
    svg.selectAll(".area").each(function classifyAreas(current, index) {
      const existingClasses = this.classList
      existingClasses.add(`area-${metadata.lines[index]}`)
      this.setAttribute("class", existingClasses)
    })

    const linesToAxis = svg.append("g")

    // Patient's data
    // Add line for the patient's growth
    const linesP = svg.selectAll("pG").data([measurements]).attr("class", "pG").enter()
    linesP.append("path").attr("class", "pLine").attr("d", line)

    // Dots at each data point
    svg
      .selectAll(".dot")
      .data(measurements)
      .enter()
      .append("circle")
      .attr("class", "dot")
      .call(dotHandler())
      .attr("cx", (d) => xScale(d[0]))
      .attr("cy", (d) => yScale(d[1]))
      .attr("r", patientDotSize)
      .attr("data-tooltip", (d, i) => tooltips[i])

    // Add axes
    // X axis
    const xAxis = axisBottom(xScale).ticks(tickCount)

    if (chartName === "wfl" || chartName === "wfh") {
      xAxis.tickFormat(numLocale.format(".1f"))
    }

    svg.append("g").attr("class", "x axis").attr("transform", `translate(0,${height})`).call(xAxis)

    // Y axis
    const yAxis = axisLeft(yScale).ticks(tickCount)

    if (chartName === "wfl" || chartName === "wfh") {
      yAxis.tickFormat(numLocale.format(".1f"))
    }

    svg.append("g").attr("class", "y axis").call(yAxis)

    // X-axis text
    svg
      .append("text")
      .attr("text-anchor", "middle")
      .attr("transform", `translate(${width / 2},${height + margin.top})`)
      .classed("axis-label", true)
      .text(metadata.xAxisTitle)

    // Y-axis text
    svg
      .append("text")
      .attr("text-anchor", "middle")
      .attr("transform", `translate(${margin.left * -0.75},${height / 2})rotate(-90)`)
      .classed("axis-label", true)
      .text(metadata.yAxisTitle)

    // Line labels
    let xOffset: number
    let yOffset: number
    for (let i = 0; i < metadata.lines.length; i += 1) {
      xOffset = xScale(lastTuples[i][0])
      xOffset += 2 // a little space better graph and text
      yOffset = yScale(lastTuples[i][1])
      yOffset += 4 // center text on line

      svg
        .append("text")
        .attr("class", "line-label")
        .attr("transform", `translate(${xOffset},${yOffset})`)
        .text(metadata.lines[i])
    }

    const tooltipOffset = 10
    const tooltipGroup = svg.append("g")

    const tooltipTextBackground = tooltipGroup
      .append("rect")
      .attr("x", tooltipOffset)
      .attr("y", tooltipOffset)
      .attr("width", 0)
      .attr("height", 0)
      .attr("class", "tooltipTextBackground")

    const tooltipText = tooltipGroup
      .append("text")
      .attr("x", tooltipOffset)
      .attr("y", tooltipOffset)
      .attr("class", "tooltipText")
      .text("")

    svg
      .append("text")
      .attr("x", width / 2)
      .attr("y", -10)
      .attr("text-anchor", "middle")
      .classed("chart-title", true)
      .text(metadata.title)

    function dotHandler() {
      return (selection) => {
        selection.on("mouseover", function highlighter() {
          // d: MouseEvent
          // i: the "measurements" array item for the selected point [x, y]
          // Select current dot
          d3Select(this).attr("class", "dotSelected")

          tooltipText.text(d3Select(this).attr("data-tooltip"))

          // Update text background
          const tooltipHeightPadding = 10
          const tooltipWidthPadding = 0
          const bbox = tooltipText.node().getBBox()
          tooltipTextBackground
            .attr("width", width + tooltipWidthPadding * 2)
            .attr("height", bbox.height + tooltipHeightPadding)
            .attr("x", 0 - tooltipWidthPadding)
            .attr("y", tooltipOffset - bbox.height)
            .style("visibility", "visible")
        })
        selection.on("mouseout", () => {
          // Deselect the point
          d3SelectAll("circle.dotSelected").attr("class", "dot")
          // Hide tooltip
          linesToAxis.selectAll(".rect-to-axis").data([]).exit().remove()
          tooltipTextBackground.style("visibility", "hidden")
          tooltipText.text("")
        })
      }
    }
  }
  return drawChart
}

interface Point {
  date: Date,
  value: number,
  tooltip?: string,
}

// These represent the bounds of "moderate acute malnutrition"; at or above
// the upper val is "normal" and below is "severe acute malnutrition".
const MUAC_CUTOFF_AGE_BRACKETS = [
  [13.5, 14.5],  // 5-10 yrs
  [16, 18.5],    // 10-15 yrs
  [18.5, 21],    // 15-18 years
]

const MUAC_CUTOFF_SEVERITIES = [
  $gettext("Normal for age"),
  $gettext("Moderate Thinness"),
  $gettext("Severe Thinness"),
]

// Return all MUAC measurements *up to & including this one* for this child
// after their 5th bday in a format useful for our charting.
// (Assumes that child's age at assessment was already over 5.)
//
// Only include the most recent 6 tests.
async function getMuacValsForCutoffChart(assessment: GrowthAssessment): Promise<Array<Point>> {
  const allAssessments = await getAllSavedGrowthAssessmentsForChild(assessment.child.id)
  // Filter down to previous assessments that contain MUAC then turn them into model instances
  const filteredAssessments = allAssessments
    .filter((a: IGrowthAssessment) => {
      return a.dateOfAssessment < assessment.dateOfAssessment && a.muacInCm
    })
    .map((a: IGrowthAssessment) => {
      return new GrowthAssessment(assessment.child, a)
    })
  // Add ourself to the end
  filteredAssessments.push(assessment)

  // Finally, munge the data–for assessments when the child was older than 5–and return.
  return filteredAssessments
    .filter((a: GrowthAssessment) => a.ageInYears > 5)
    .map((a: GrowthAssessment) => {
      return {
        date: a.dateOfAssessment,
        value: a.muacInCm,
        tooltip: $gettext("Date: %{date}; Arm size: %{value} cm", { date: a.dateOfAssessment.toLocaleDateString(), value: a.muacInCm.toString() }),
      }
    })
    .slice(-6)
}

// Returns a list of dicts for each of the severities, sorted as
// MUAC_CUTOFF_SEVERITIES is. Dicts each have keys: label, points.
//
// Expects a list of dicts coming from get_muac_vals_for_cutoff_chart.
function getMuacCutoffLines(values: Array<Point>, child: Child) {

  // Return the appropriate MUAC_CUTOFF_AGE_BRACKETS
  // index based on a given age. **Returns the range in reverse order**,
  // from healthy lower boundary down to severe malnutrition upper bound.
  function getAgeBracketRange(ageInYears: number) {
    let index: number
    if (ageInYears < 10) index = 0
    else if (ageInYears < 15) index = 1
    else index = 2
    return MUAC_CUTOFF_AGE_BRACKETS[index].toReversed()
  }

  let startDate = values[0].date
  let stopDate = values[values.length - 1].date
  // Create some separation btw outer points and the edges of chart.
  startDate = new Date(Math.max(
    dayjs(startDate).subtract(1, "month").toDate().getTime(),
    dayjs(child.dob).add(5, "year").toDate().getTime()
  ))
  stopDate = dayjs(stopDate).add(1, "month").toDate()

  const startAge = child.getAgeInYears(startDate)
  const stopAge = child.getAgeInYears(stopDate)

  // Start with the left-most points for each line
  const results = MUAC_CUTOFF_SEVERITIES.map((severity, index) => {
    return {
      "label": severity,
      "points": [{
        date: startDate,
        value: index ? getAgeBracketRange(startAge)[index - 1] : null
      }]
    }
  })

  // Base case will have only two points per line. But check if this isn't
  // the base: does the chart include either/both of the inflection
  // points? (Requiring intermediate points for each line.)
  if (startAge <= 10 && 10 <= stopAge) {
    const tenthBday = dayjs(child.dob).add(10, "year")
    // Push two points: the end of the 5-year-old line and the start of
    // the 10-year-old line
    MUAC_CUTOFF_AGE_BRACKETS.slice(0, 2).forEach(range_ => {
      range_ = range_.toReversed()
      range(MUAC_CUTOFF_SEVERITIES.length).forEach((i: number) => {
        results[i].points.push({
          date: tenthBday.toDate(),
          value: i ? range_[i - 1] : null
        })
      })
    })
  }
  if (startAge <= 15 && 15 <= stopAge) {
    const fifteenthBday = dayjs(child.dob).add(15, "year")
    MUAC_CUTOFF_AGE_BRACKETS.slice(1, 3).forEach(range_ => {
      range_ = range_.toReversed()
      range(MUAC_CUTOFF_SEVERITIES.length).forEach((i: number) => {
        results[i].points.push({
          date: fifteenthBday.toDate(),
          value: i ? range_[i - 1] : null
        })
      })
    })
  }

  // End with the right-most points for each line
  const stopRange = getAgeBracketRange(stopAge)
  range(MUAC_CUTOFF_SEVERITIES.length).forEach((i: number) => {
    results[i].points.push({
      date: stopDate,
      value: i ? stopRange[i - 1] : null
    })
  })
  return results
}

export async function getMuacCutoffChartDisplayer(assessment: GrowthAssessment) {
  const measurements = await getMuacValsForCutoffChart(assessment)
  const cutoffLines = getMuacCutoffLines(measurements, assessment.child)
  const { timeLocale, numLocale } = getD3Locales()

  // Static -- these don't change btw window resizes
  const wrapper = d3Select("#growth-chart-inner-wrapper")
  const margin = { top: 25, right: 60, bottom: 60, left: 50 }
  const tooltipOffset = 10
  const chartLabels = {
    chartTitle: $gettext("Arm Size"),
    xAxisTitle: $gettext("Month"),
    yAxisTitle: $gettext("Arm Size (cm)"),
  }

  wrapper.select('.chart-placeholder').remove()
  // Gather ALL the points to be able to determine the 'extents'
  const allPoints = measurements.slice()

  for (let i = 0; i < cutoffLines.length; i += 1) {
    for (let k = 0; k < cutoffLines[i].points.length; k += 1) {
      allPoints.push(cutoffLines[i].points[k])
    }
  }

  const yExtent = extent(allPoints, (d: Point) => d.value)
  // Goose y range by 1 in either direction, for a little separation
  yExtent[0] -= 1
  yExtent[1] += 1

  const xExtent = extent(allPoints, (d: Point) => d.date)

  // These correspond to CSS classes used to style the severity areas
  const areaColorClasses = ["green", "yellow", "red"]
  // Munge the severity area data into something the D3 area function can
  // handle: arrays of x (date), y (value), y0 values. areaData will be a 3-item
  // object, mapping css class to the list of points defining the area.
  const areaData = {}
  for (let i = 0; i < areaColorClasses.length; i += 1) {
    const area = []
    for (let k = 0; k < cutoffLines[i].points.length; k += 1) {
      area[k] = {
        x: cutoffLines[i].points[k].date,
        y: cutoffLines[i].points[k].value || yExtent[1],
        y0: yExtent[0], // Probably to be overridden.
      }
      if (i !== areaColorClasses.length - 1) {
        // All but the last area can look one ahead.
        area[k].y0 = cutoffLines[i + 1].points[k].value
      }
    }
    areaData[areaColorClasses[i]] = area
  }

  // Function to do actual chart drawing. Called on all window resizing.
  function drawMuacChart() {
    const width = document.getElementById("growth-chart-inner-wrapper").offsetWidth - (margin.left + margin.right)
    const height = Math.max(
      (width / 2) - (margin.top + margin.bottom),
      150
    )
    const xScale = scaleTime()
      .range([0, width])
      .domain(xExtent)

    const yScale = scaleLinear()
      .range([height, 0])
      .domain(yExtent)

    const xAxis = axisBottom(xScale)
      .ticks((width < 450) ? 5 : 8)
      .tickFormat(timeLocale.format("%b %Y"))

    const yAxis = axisLeft(yScale)
      .tickFormat(numLocale.format(".1f"))

    wrapper.select("svg").remove()
    const svg = wrapper.append("svg")
      .attr("width", width + margin.left + margin.right)
      .attr("height", height + margin.top + margin.bottom)
      .classed("chart muac-cutoff-chart", true)
      .append("g")
      .attr("transform", `translate(${margin.left},${margin.top})`)

    svg.append("g")
      .attr("class", "x axis")
      .attr("transform", `translate(0,${height})`)
      .call(xAxis)
      .selectAll("text")
      .attr("transform", "rotate(30)")
      .attr("dx", ".25em")
      .attr("dy", ".75em")
      .style("text-anchor", "start")

    // X-axis text
    svg.append("text")
      .attr("text-anchor", "middle")
      .attr("transform", `translate(${(width / 2)},${(height + margin.top + (margin.bottom / 2))})`)
      .classed("axis-label", true)
      .text(chartLabels.xAxisTitle)

    svg.append("g")
      .attr("class", "y axis")
      .call(yAxis)

    // Y-axis text
    svg.append("text")
      .attr("text-anchor", "middle")
      .attr("transform", `translate(${(margin.left * -0.75)},${(height / 2)})rotate(-90)`)
      .classed("axis-label", true)
      .text(chartLabels.yAxisTitle)

    // Severity "areas"–the colored swathes.
    const area = d3Area()
      .x((d) => xScale(d.x))
      .y0((d) => yScale(d.y0))
      .y1((d) => yScale(d.y))

    for (const color in areaData) {
      svg.append("path")
        .datum(areaData[color])
        .attr("class", `area ${color}`)
        .attr("d", area)
    }

    // Baseline Line labels. We want them at the halfway point for the region.
    // Build an array of objects with keys 'label', 'lowerBound', 'upperBound'
    const lineLabels = [
      // Normal
      {
        label: cutoffLines[0].label,
        lowerBound: cutoffLines[1].points.slice(-1)[0].value,
        upperBound: yExtent[1],
      },
      // Moderate
      {
        label: cutoffLines[1].label,
        lowerBound: cutoffLines[2].points.slice(-1)[0].value,
        upperBound: cutoffLines[1].points.slice(-1)[0].value,
      },
      // Severe
      {
        label: cutoffLines[2].label,
        lowerBound: yExtent[0],
        upperBound: cutoffLines[2].points.slice(-1)[0].value,
      }
    ]
    lineLabels.forEach(label => {
      const midpoint = (label.upperBound + label.lowerBound) / 2
      svg.append("foreignObject")
        .attr("transform", `translate(${(width + 3)},${yScale(midpoint) - 15})`)
        .attr("class", "wrapped-line-label")
        .html(label.label)
    })

    // Dots for each point on each line
    svg.selectAll(".dot")
      .data(measurements)
      .enter()
      .append("circle")
      .attr("class", "dot")
      .call(dotHandler())
      .attr("cx", (d: Point) => xScale(d.date))
      .attr("cy", (d: Point) => yScale(d.value))
      .attr("r", 2)

    // Chart title
    svg.append("text")
      .attr("x", width / 2)
      .attr("y", -10)
      .attr("text-anchor", "middle")
      .classed("chart-title", true)
      .text(chartLabels.chartTitle)

    // Tool tip setup
    const linesToAxis = svg
      .append("g")
      .attr("class", "lines-to-axis")
    const tooltipGroup = svg.append("g")

    const tooltipTextBackground = tooltipGroup.append("rect")
      .attr("x", tooltipOffset)
      .attr("y", tooltipOffset)
      .attr("width", 0)
      .attr("height", 0)
      .attr("class", "tooltipTextBackground")

    const tooltipText = tooltipGroup.append("text")
      .attr("x", tooltipOffset)
      .attr("y", tooltipOffset)
      .attr("class", "tooltipText")
      .text("")

    function dotHandler() {
      return (selection) => {
        selection.on("mouseover", (mouseEvent: MouseEvent, point: Point) => {
          // Select current dot
          d3Select(this).attr("class", "dotSelected")

          // Update text using the accessor function
          tooltipText.text(point.tooltip || "")

          // Update text background
          const dottedSegmentLength = 3 // used below, too, for linesToAxis
          const tooltipHeightPadding = 5
          const bbox = tooltipText
            .node()
            .getBBox()
          tooltipTextBackground
            .attr("width", width)
            .attr("height", bbox.height + tooltipHeightPadding)
            .attr("x", 0)
            .attr("y", tooltipOffset - bbox.height)
            .style("visibility", "visible")

          // Create a rectangle that stretches to the axes, to show the axis is right.
          const linesToAxisWidth = xScale(point.date)
          const linesToAxisHeight = height - yScale(point.value)
          const halfRectLength = linesToAxisWidth + linesToAxisHeight

          // Draw top and right sides of rectangle as dotted. Hide bottom and left sides
          const dottedSegments = Math.floor(halfRectLength / dottedSegmentLength)
          const nonDottedLength = halfRectLength * 2
          const dashArrayStroke = []

          for (let j = 0; j < dottedSegments; j += 1) {
            dashArrayStroke.push(dottedSegmentLength)
          }
          // if even number, add extra filler segment to make sure 2nd half of rectangle is hidden
          if ((dottedSegments % 2) === 0) {
            const extraSegmentLength = halfRectLength - (dottedSegments * dottedSegmentLength)
            dashArrayStroke.push(extraSegmentLength)
          }
          dashArrayStroke.push(nonDottedLength)

          linesToAxis.selectAll(".rect-to-axis")
            .data([point])
            .enter().append("rect")
            .attr("class", "rect-to-axis")
            .style("stroke-dasharray", dashArrayStroke.toString())
            .attr("y", yScale(point.value))
            .attr("width", linesToAxisWidth)
            .attr("height", linesToAxisHeight)
        })
        selection.on("mouseout", () => {
          // Deselect current dot
          d3SelectAll("circle.dotSelected").attr("class", "dot")
          // Hide orange bg rect
          tooltipTextBackground.style("visibility", "hidden")
          // Zero out tooltip text
          tooltipText.text("")
          // Destroy dotted lines
          linesToAxis.selectAll(".rect-to-axis")
            .data([])
            .exit()
            .remove()
        })
      }
    }
  }
  return drawMuacChart
}
